import { FlickingError } from '@egjs/react-flicking';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { RefObject } from 'react';
import type Flicking from '@egjs/react-flicking';

import { ButtonBase } from '~/components/Buttons/Components/ButtonBase';
import { getAutoPlayPlugin } from '~/site-marketing-messaging/Utils/SiteMarketingMessaging.utils';
import { DIRECTION } from '~/site-marketing-messaging/SiteMarketingMessaging.constants';

import type { SiteMarketingMessaging } from '~/site-marketing-messaging/Models/SiteMarketingMessaging.model';

import styles from '~/site-marketing-messaging/Components/SiteMarketingMessagingNav.module.scss';

export const SiteMarketingMessagingNav = observer(({
	direction,
	flickingRef,
	siteMarketingMessagingModel,
	siteMarketingMessagingModel: {
		activeMessageTemplate,
	},
}: {
	direction: keyof typeof DIRECTION,
	flickingRef: RefObject<Flicking>,
	siteMarketingMessagingModel: SiteMarketingMessaging
}) => {
	async function handleClick() {
		try {
			const autoPlayPlugin = getAutoPlayPlugin({ flickingRef });

			if (direction === DIRECTION.NEXT) {
				await flickingRef.current?.next();
			}

			if (direction === DIRECTION.PREV) {
				await flickingRef.current?.prev();
			}

			autoPlayPlugin?.stop();

			siteMarketingMessagingModel.paused = true;
		} catch (error) {
			if (!(error instanceof FlickingError)) {
				throw error;
			}
		}
	}

	return (
		<ButtonBase
			className={
				classNames(
					styles['site-marketing-messaging-nav'],
					styles[`site-marketing-messaging-nav-${activeMessageTemplate}`],
					styles[`site-marketing-messaging-nav-${direction}`],
				)
			}
			data-qa={`site-marketing-messaging-nav-${direction}`}
			data-tr-link-event-comp-name="general functionality"
			onClick={handleClick}
		>
			<span className="tw-sr-only">
				Navigate to {direction === DIRECTION.NEXT ? 'next' : 'previous'} message
			</span>
		</ButtonBase>
	);
});
